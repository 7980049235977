<template>
  <div
    v-if="exampleDocument && exampleDocument.document && exampleDocument.document.file"
    class="mt-2"
  >
    <b-button
      v-if="exampleDocument.document.file"
      :href="'/files/' + exampleDocument.document.file ? exampleDocument.document.file.guid : ''"
      target="_blank"
      @click.prevent="downloadFile(exampleDocument.document.file)"
    >Скачать здесь</b-button>
  </div>
</template>

<script>
import {getExampleDocument, downloadFile} from '@/services/api';

export default {
  name: 'ExampleDocument',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exampleDocument: null,
    };
  },
  watch: {
    code() {
      if ( this.code) {
        this.getExampleDocument(this.code);
      }
    },
  },
  mounted() {
    if ( this.code) {
      this.getExampleDocument(this.code);
    }
  },
  methods: {
    downloadFile,
    async getExampleDocument() {
      this.exampleDocument = (await getExampleDocument(this.code)).data;
    },
  },
};
</script>

<style scoped>

</style>
